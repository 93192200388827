module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Developing Entrepreneural Mongolia", // Navigation and Site Title
  titleAlt: "Developing Entrepreneural Mongolia", // Title for JSONLD
  description:
    "Developing Entrepreneural Mongolia - Funding Sustainable Future",
  headline: "Developing Entrepreneural Mongolia", // Headline for schema.org JSONLD
  url: "https://dem.mn", // Domain of your site. No trailing slash!
  logo: "/images/logo.png", // Used for SEO
  ogLanguage: "mn_MN", // Facebook Language

  // JSONLD / Manifest
  favicon: "./static/favicon.png", // Used for manifest favicon generation
  shortName: "DEM.mn", // shortname for manifest. MUST be shorter than 12 characters
  author: "dem", // Author for schemaORGJSONLD
  themeColor: "#096bd8",
  backgroundColor: "#EBEDF2",

  twitter: "@dem.mn", // Twitter Username
  facebook: "dem.mn", // Facebook Site Name
  googleAnalyticsID: "UA-XXXXXX-X",

  skipNavId: "reach-skip-nav", // ID for the "Skip to content" a11y feature
};
