import * as React from "react";
import Drawer from "rc-drawer";
import Link from "../LocalizedLink";

import "rc-drawer/assets/index.css";
import useLocale from "../../contexts/locale";

interface IProps {
  data: {
    header_nav_items: Array<{
      text: string;
      target_id: {
        text: string;
      };
    }>;
    form_link: {
      url: string;
      target: string;
    };
  };
}

const MobileHeader = ({ data }: IProps) => {
  const [isExpanded, setExpanded] = React.useState(false);
  const { locale, i18n } = useLocale();
  const i18nData = (i18n as any)[locale];

  return (
    <Drawer
      width={250}
      open={isExpanded}
      placement="right"
      onClose={() => setExpanded(false)}
      onHandleClick={() => setExpanded(!isExpanded)}
    >
      <ul className="navbar-nav" style={{ padding: "1rem" }}>
        {data.header_nav_items.map((item, index) => (
          <li key={`nav-item-${index}`} className="nav-item">
            <Link
              to={item.target_id.text}
              className="nav-link"
              onClick={() => setExpanded(false)}
            >
              {item.text}
            </Link>
          </li>
        ))}
        <a
          href={data.form_link.url}
          className="btn"
          target={data.form_link.target}
          rel="noopener noreferrer"
        >
          <span className="btn-text">
            {i18nData.apply} <i className="far fa-long-arrow-right"></i>
          </span>
        </a>
      </ul>
    </Drawer>
  );
};

export default MobileHeader;
