import React from "react";

export type Locale = {
  locale: "mn-mn" | "en-us";
  i18n?: any;
};

export const LocaleContext = React.createContext<Locale>({
  locale: "mn-mn",
});

const useLocale = () => React.useContext(LocaleContext);

export default useLocale;
