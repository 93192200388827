import * as React from "react";
import axios from "axios";
import useLocale from "../../contexts/locale";

const ExchangeWidget = () => {
  const [data, setData] = React.useState();
  const [isLoading, setLoading] = React.useState(true);

  const { locale, i18n } = useLocale();
  const i18nData = (i18n as any)[locale];

  React.useEffect(() => {
    axios
      .get(
        "https://monxansh.appspot.com/xansh.json?currency=USD|EUR|JPY|GBP|RUB|CNY|KRW",
        {}
      )
      .then(response => {
        setData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="exchange-widget">
      {isLoading && "Loading..."}
      {data && (
        <div className="exchange-table">
          <p>
            Эх сурвалж: <strong>Монгол банк</strong>
          </p>
          <table>
            <thead>
              <tr>
                <th>{i18nData.currency}</th>
                <th>{i18nData.rate}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((currency: any) => (
                <tr key={currency.code}>
                  <td>
                    <span id={currency.code} title={currency.name}>
                      {currency.code}
                    </span>
                  </td>
                  <td>{currency.rate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <style>{`
        .exchange-widget {
          position: fixed;
          right: 0;
          top: 170px;
          transform: translate(100%, 0);
          transition: all 0.3s ease;
          z-index: 9;
        }
        .exchange-widget:hover,
        .exchange-widget.active {
          transform: translate(0, 0);
        }
        .exchange-widget::before {
          content: "\\f155";
          font-family: "Font Awesome 5 Pro";
          background: #096bd8;
          width: 40px;
          height: 40px;
          color: #fff;
          text-align: center;
          font-weight: bold;
          line-height: 40px;
          position: absolute;
          top: 0;
          left: -40px;
          border-radius: 5px 0 0 5px;
          box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15)
        }
        .exchange-table {
          padding: 1rem 2rem;
          position: relative;
          background: #fff;
          box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15)
        }
        .exchange-table table td,
        .exchange-table table th {
          padding: 0.5rem;
        }
        .exchange-table table td {
          border-top: 1px solid #bbb;
        }
        #AED {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 0;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #AUD {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -66px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #BGN {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -132px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #CAD {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -198px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #CHF {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -264px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #CNY {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -330px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #CZK {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -396px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #DKK {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -462px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #EGP {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -528px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #EUR {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -594px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #GBP {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -660px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #HKD {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -726px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #HUF {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -792px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #IDR {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -858px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #INR {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -924px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #JPY {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -990px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #KPW {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1056px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #KRW {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1122px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #KWD {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1188px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #KZT {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1254px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #MYR {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1320px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #NOK {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1386px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #NPR {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1452px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #NZD {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1518px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #PLN {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1584px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #RUB {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1650px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #SEK {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1716px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #SGD {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1782px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #THB {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1848px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #TRY {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: 0 -1914px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #TWD {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: -66px 0;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #UAH {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: -66px -66px;
          width: 16px;
          height: 11px;
          padding-left: 25px;
        }
        #USD {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: -66px -127px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
        #ZAR {
          background: url(/images/tugnuud.png) no-repeat top left;
          background-position: -66px -193px;
          width: 16px;
          height: 16px;
          padding-left: 25px;
        }
      `}</style>
    </div>
  );
};

export default ExchangeWidget;
