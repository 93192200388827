import * as React from "react";
import Headroom from "react-headroom";
import useMedia from "use-media";
import Link from "../LocalizedLink";
import MobileHeader from "./MobileHeader";
import useLocale from "../../contexts/locale";

interface IProps {
  data: {
    header_nav_items: Array<{
      text: string;
      target_id: {
        text: string;
      };
    }>;
    form_link: {
      url: string;
      target: string;
    };
  };
}

const DesktopHeader = ({ data }: IProps) => {
  const { locale, i18n } = useLocale();
  const i18nData = (i18n as any)[locale];

  return (
    <div className="col-xl-10 col-lg-10">
      <div className="header-button f-right d-none d-lg-block">
        <a
          href={data.form_link.url}
          className="btn"
          target={data.form_link.target}
          rel="noopener noreferrer"
        >
          <span className="btn-text">
            {i18nData.apply} <i className="far fa-long-arrow-right"></i>
          </span>
        </a>
      </div>
      <div className="main-menu text-right">
        <nav id="mobile-menu">
          <ul>
            {data.header_nav_items.map((item, index) => (
              <li key={`nav-item-${index}`}>
                <Link to={item.target_id.text}>{item.text}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

const StickyHeader = ({ data }: IProps) => {
  const isMobile = useMedia({ maxWidth: 960 });

  const HeaderComponent = isMobile ? MobileHeader : DesktopHeader;

  return (
    <Headroom>
      <div id="sticky-header" className="main-menu-area">
        <div className="container">
          <div className="row">
            <div className="col-xs-10 col-xl-2 col-lg-2 d-flex align-items-center">
              <div className="logo">
                <Link to="/">
                  <img src="/images/logo.png" alt="DEM" />
                </Link>
              </div>
            </div>
            <HeaderComponent data={data} />
          </div>
        </div>
      </div>
    </Headroom>
  );
};

export default StickyHeader;
