import * as React from "react";
import { Link } from "gatsby";
import useLocale from "../../contexts/locale";

interface IProps {
  data: {
    footer_social_items: Array<{
      title: {
        text: string;
      };
      link: {
        url: string;
      };
    }>;
    address_short: {
      text: string;
    };

    phone: {
      text: string;
    };

    email: {
      text: string;
    };
  };
  location: Location;
}

const HeaderTopArea = ({ data, location }: IProps) => {
  const { locale } = useLocale();
  return (
    <div className="header-top-area grey-bg d-none d-md-block">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-6 d-flex align-items-center">
            <div className="header-info">
              <span>
                <i className="far fa-map-marker-alt" />{" "}
                {data.address_short.text}
              </span>
              <span className="header-ph">
                <i className="far fa-phone" /> {data.phone.text}
              </span>
              <span className="header-en">
                <i className="far fa-envelope-open" /> {data.email.text}
              </span>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6">
            <div className="header-right f-right ">
              <div className="header-lang  pos-rel f-right">
                <div className="lang-icon">
                  <i className="far fa-globe" />
                  {locale === "mn-mn" ? (
                    <Link
                      hrefLang="en-us"
                      to={`/en${location.pathname}`}
                    >{` English`}</Link>
                  ) : (
                    <Link
                      hrefLang="mn-mn"
                      to={location.pathname.replace("/en", "")}
                    >{` Монгол`}</Link>
                  )}
                </div>
              </div>
              <div className="header-icon f-right">
                {data.footer_social_items.map(item => (
                  <a
                    href={item.link.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    key={item.title.text.toLowerCase()}
                  >
                    <i className={`fab fa-${item.title.text.toLowerCase()}`} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopArea;
