import * as React from "react";
import { Service } from "../Section/Services";
import Link from "../LocalizedLink";
import useLocale from "../../contexts/locale";

interface IProps {
  data: {
    footer_text: {
      text: string;
    };
    footer_nav_items: Array<{
      text: string;
      link: {
        url: string;
      };
    }>;
    footer_social_items: Array<{
      title: {
        text: string;
      };
      link: {
        url: string;
      };
    }>;
    address: {
      text: string;
    };

    phone: {
      text: string;
    };

    email: {
      text: string;
    };
  };
  services: Service[];
}

const Footer = ({ data, services }: IProps) => {
  const { locale, i18n } = useLocale();
  const i18nData = (i18n as any)[locale];

  return (
    <footer>
      <div className="footer-area grey-bg pt-80 pb-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="footer-wrapper mb-30">
                <div className="footer-logo">
                  <a href="index.html">
                    <img src="assets/img/logo/logo.png" alt="" />
                  </a>
                </div>
                <div className="footer-text">
                  <p>{data.footer_text.text}</p>
                </div>
                <div className="footer-icon">
                  {data.footer_social_items.map(item => (
                    <a
                      href={item.link.url}
                      rel="noopener noreferrer"
                      target="_blank"
                      key={item.title.text.toLowerCase()}
                    >
                      <i
                        className={`fab fa-${item.title.text.toLowerCase()}`}
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="footer-wrapper mb-30">
                <h4 className="footer-title">{i18nData.footerServices}</h4>
                <ul className="fotter-menu">
                  {services.map(service => (
                    <li key={`footer-service-${service.uid}`}>
                      <Link to={`/services/${service.uid}`}>
                        {service.data.title.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="footer-wrapper mb-30">
                <h4 className="footer-title">{i18nData.footerQuickLink}</h4>
                <ul className="fotter-menu">
                  {data.footer_nav_items.map((item, index) => {
                    if (!item.link) {
                      return (
                        <li key={`footer-link-${index}`}>
                          <a href="#">{item.text}</a>
                        </li>
                      );
                    }

                    const urlContainsHash = item.link.url.indexOf("//#") > 0;
                    const aProps: any = {
                      href: urlContainsHash
                        ? item.link.url.replace("https:/", "")
                        : item.link.url,
                    };

                    if (!urlContainsHash) {
                      aProps.rel = "noopener noreferrer";
                      aProps.target = "_blank";
                    }

                    return (
                      <li key={`footer-link-${index}`}>
                        <a {...aProps}>{item.text}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="footer-wrapper mb-30">
                <h4 className="footer-title">{i18nData.footerContactUs}</h4>
                <ul className="contact-link">
                  <li>
                    <div className="contact-address-icon">
                      <i className="far fa-phone"></i>
                    </div>
                    <div className="contact-address-text">
                      <h4>{data.phone.text}</h4>
                    </div>
                  </li>
                  <li>
                    <div className="contact-address-icon">
                      <i className="far fa-envelope-open"></i>
                    </div>
                    <div className="contact-address-text">
                      <h4>{data.email.text}</h4>
                    </div>
                  </li>
                  <li>
                    <div className="contact-address-icon">
                      <i className="far fa-map-marker-alt"></i>
                    </div>
                    <div className="contact-address-text">
                      <h4>{data.address.text}</h4>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom-area pt-50">
          <div className="container">
            <div className="footer-bg-bottom">
              <div className="copyright">
                <p>
                  ©{new Date().getFullYear()}, {i18nData.copyright}.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
