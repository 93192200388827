import * as React from "react";
import Link from "../LocalizedLink";

const ExchangeWidget = () => {
  return (
    <>
      <Link to="/calculator" className="calculator-widget"></Link>
      <style>{`
        .calculator-widget {
          position: fixed;
          right: 0;
          top: 120px;
          transition: all 0.3s ease;
          z-index: 9;
        }
        .calculator-widget::before {
          content: "\\F1EC";
          font-family: "Font Awesome 5 Pro";
          background: #096bd8;
          width: 40px;
          height: 40px;
          color: #fff;
          text-align: center;
          font-weight: bold;
          line-height: 40px;
          position: absolute;
          top: 0;
          left: -40px;
          border-radius: 5px 0 0 5px;
          box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15)
        }
      `}</style>
    </>
  );
};

export default ExchangeWidget;
