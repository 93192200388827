const website = require("./website");

module.exports = {
  "mn-mn": {
    default: true,
    path: "",
    locale: "mn-mn",
    siteLanguage: "mn",
    ogLang: "mn_MN",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription:
      "Developing Entrepreneural Mongolia - Funding Sustainable Future",
    headline: "Developing Entrepreneural Mongolia",
    apply: "Хүсэлт илгээх",
    readMore: "Дэлгэрэнгүй",
    footerServices: "Бүтээгдэхүүн",
    footerQuickLink: "Бусад",
    footerContactUs: "Холбоо барих",
    copyright: "ДЭМ ББСБ Зохиогчийн эрхээр хамгаалагдсан",
    blog: "Мэдээ",
    currency: "Валют",
    rate: "Ханш",
    calculator: "Зээлийн тооцоолуур",
    total: "Нийт: ",
    loan: {
      amount: "Зээлийн хэмжээ",
      term: "Зээлийн хугацаа /сараар/",
      rate: "Зээлийн хүү /сараар/",
      repayment: "Зээлийн эргэн төлөлт",
      equalMonthlyPayment: "Нийт төлбөр тэнцүү",
      equalMonthlyLoanPayment: "Үндсэн төлбөр тэнцүү",
      equalMonthlyPaymentDescription:
        "Та {0} хэмжээний төгрөгийн зээлийг сарын {1}%-ийн хүүтэйгээр {2} сарын хугацаанд зээлэхэд сард {3} төгрөг төлнө.",
      equalMonthlyLoanPaymentDescription:
        "Та {0} хэмжээний төгрөгийн зээлийг сарын {1}%-ийн хүүтэйгээр {2} сарын хугацаанд зээлэхэд эхний сард {3} төгрөг төлж, сар бүр төлөх хэмжээ буурна.",
      monthlyPayment: "Үндсэн зээлийн төлбөр",
      interestPayment: "Зээлийн хүү",
      totalAmountOfPayment: "Сарын нийт төлбөр",
      month: "Сар",
      balance: "Зээлийн үлдэгдэл",
    },
  },
  "en-us": {
    path: "en",
    locale: "en-US",
    siteLanguage: "en",
    ogLang: "en_US",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    apply: "Apply",
    readMore: "Read more",
    footerServices: "Our services",
    footerQuickLink: "Quick links",
    footerContactUs: "Contact us",
    copyright: "DEM All rights reserved",
    blog: "Blog",
    currency: "Currency",
    rate: "Rate",
    calculator: "Loan calculator",
    total: "Total: ",
    loan: {
      amount: "Loan amount",
      term: "Loan term /by month/",
      rate: "Interest rate /by month/",
      repayment: "Loan repayment",
      equalMonthlyPayment: "Equal monthly payment",
      equalMonthlyLoanPayment: "Equal monthly loan payment",
      equalMonthlyPaymentDescription:
        "For a loan of MNT {0} with a monthly interest rate of {1}% for {2} months, monthly payments during loan term will be {3}.",
      equalMonthlyLoanPaymentDescription:
        "For a loan of MNT {0} with a monthly interest rate of {1}% for {2} months, the first monthly payment will be {3}. The loan payment amounts decrease over the life of the loan.",
      monthlyPayment: "Monthly payment",
      interestPayment: "Interest payment",
      totalAmountOfPayment: "Total payment",
      month: "Month",
      balance: "Loan balance",
    },
  },
};
