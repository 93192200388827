import * as React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header";
import Footer from "./Footer";

import i18n from "../../config/i18n";
import { Service } from "./Section/Services";
import ExchangeWidget from "./Exchange";
import CalculatorWidget from "./Calculator";
import { LocaleContext } from "../contexts/locale";

import "../assets/css/bootstrap.min.css";
import "../assets/css/fontawesome-all.min.css";
import "../assets/scss/main.scss";
import "../assets/css/responsive.css";

interface IProps {
  location: Location;
  title: string;
  [key: string]: any;
  pageContext: { locale: "mn-mn" | "en-us" };
}

const query = graphql`
  query MyQuery {
    allPrismicLayout {
      edges {
        node {
          lang
          data {
            site_name
            header_nav_items {
              text
              target_id {
                text
              }
            }
            footer_text {
              text
            }
            footer_nav_items {
              text
              link {
                url
              }
            }
            footer_social_items {
              title {
                text
              }
              link {
                url
              }
            }
            address {
              text
            }
            address_short {
              text
            }
            phone {
              text
            }
            email {
              text
            }
            form_link {
              url
              target
            }
          }
        }
      }
    }
    services: allPrismicService(
      sort: { fields: data___showorder, order: ASC }
    ) {
      nodes {
        uid
        lang
        data {
          title {
            text
          }
        }
      }
    }
  }
`;

const Layout = (props: IProps) => {
  const { children } = props;
  const data = useStaticQuery(query);
  const locale = props.pageContext.locale;

  const currentLayoutData = data.allPrismicLayout.edges.find(
    ({ node }: any) => node.lang === locale
  ).node.data;

  const services = data.services.nodes.filter(
    (service: Service) => service.lang === locale
  );

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <Helmet>
        <title>{props.title}</title>
        <link
          href="https://fonts.googleapis.com/css?family=Ubuntu&display=swap"
          rel="stylesheet"
        />
        <link rel="icon" type="image/png" href="/favicon.png" />
      </Helmet>
      <Header location={props.location} data={currentLayoutData} />
      <main>{children}</main>
      <ExchangeWidget />
      <CalculatorWidget />
      <Footer data={currentLayoutData} services={services} />
    </LocaleContext.Provider>
  );
};

export default Layout;
