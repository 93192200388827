import * as React from "react";
import TopArea from "./TopArea";
import StickyHeader from "./StickyHeader";

interface IProps {
  data: {
    header_nav_items: Array<{
      text: string;
      target_id: {
        text: string;
      };
    }>;
    footer_social_items: Array<{
      title: {
        text: string;
      };
      link: {
        url: string;
      };
    }>;
    address_short: {
      text: string;
    };
    phone: {
      text: string;
    };
    email: {
      text: string;
    };
    form_link: {
      url: string;
      target: string;
    };
  };
  location: Location;
}

const Header = ({ data, location }: IProps) => {
  return (
    <header>
      <TopArea data={data} location={location} />
      <StickyHeader data={data} />
    </header>
  );
};

export default Header;
