import React from "react";
import { Link } from "gatsby";
import locales from "../../config/i18n";
import useLocale from "../contexts/locale";

interface IProps {
  to: string;
  [key: string]: any;
}

const LocalizedLink = ({ to, ...props }: IProps) => {
  const { locale } = useLocale();
  const currentLocale = locales[locale];
  const isIndex = to === "/";
  const path = currentLocale.default
    ? to
    : `${currentLocale.path}${isIndex ? "" : to}`;

  return <Link {...props} to={path} />;
};

export default LocalizedLink;
